/*color*/
$white: #ffffff;
$black: #000000;
$color: #AD79F7;
$grey: #8C8C8C;

@mixin trans1 {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

/* Login Starts header
====================== */
main.login-main{display: flex;flex-direction: row-reverse;height:100vh;min-height:650px;
  @media(max-width:767px){display: inline-block;height:auto;}
  .login-left{
    position: relative;display: flex;width: 40%;
    height: 100%;background-color: $white;padding-top: 50px;
    align-items: center;padding:50px 10% 50px;
    @media(max-width:1400px){padding:50px 7% 50px;}
    @media(max-width:1200px){padding:50px 30px 50px;width:450px;}
    @media(max-width:991px){padding:30px;}
    @media(max-width:767px){display: inline-block;width:100%;}
    .login-logo{position: absolute;left: 30px;top: 30px;max-width: 70px;
      @media(max-width:767px){display: none;}
    }
    .login-left-inner{
      display: inline-block;width: 100%;
      .input-btn{
        position: absolute;left: 10px;top: 15px;bordeR:none;
        color: $color;padding: 0;background-color: transparent;
      }
    }
    h2{font-weight: 700;color: $black;margin-bottom:30px;font-size: 45px;
 
      @media(max-width:1200px){font-size: 40px;}
      @media(max-width:991px){font-size: 35px;}
      @media(max-width:575px){text-align: center;}
    }
    label{font-size: 16px;font-weight: 500;color: $grey;}
   input{padding-left: 35px;}
  }
  .login-right{
    display: flex;align-items: center;justify-content: end;
    width: 60%;position: relative;
    @media(max-width:1200px){width: 100%;}
    @media(max-width:767px){height: 300px;}
    .bg-layer{z-index: -1;}
    .login-right-inner{
      background-color: $color;width:90%;padding:30px 30px 40px;
      @media(max-width:991px){padding:15px 15px 20px;}
      h1{font-size:60px;color: $white;font-weight:700;margin-top: 0;
        @media(max-width:1400px){font-size: 45px;}
        @media(max-width:1200px){font-size: 35px;}
        @media(max-width:991px){font-size: 30px;}
        @media(max-width:575px){font-size: 24px;}
      }
      h3{font-size: 30px;color: $white;margin:0;
        @media(max-width:1200px){font-size: 20px;}
        @media(max-width:575px){font-size: 16px;}
      }
    }
  }
}
/* Login Ends header
==================== */