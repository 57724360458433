$white: #ffffff;
$black: #000000;
$color: #ad79f7;
$grey: #8c8c8c;
@mixin trans1 {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* Side Bar Starts Here
======================= */
.sidebar {
  position: absolute;
  background-color: transparent;
  overflow: hidden;
  border-right: 2px solid #f0f0f0;
  left: 0;
  top: 0px;
  min-height: 410px;
  z-index: 99;
  height: 100%;
  padding-top: 20px;
  @include trans1();
  @media (max-width: 991px) {
    width: 60px;
    background-color: $white;
    padding-top: 80px;
    height: 100vh;
    min-height: 100vh;
    position: fixed;
    &.active {
      background-color: $white;
      .logo-black,
      .logo-white {
        max-width: 150px;
      }
    }
  }
  .logo-black,
  .logo-white {
    max-width: 150px;
    @include trans1();
    @media (max-width: 991px) {
      max-width: 40px;
      margin-top: 50px;
    }
  }

  &.active {
    width: 50px;
    z-index: 99999;
    .toggler {
      top: 0px;
      background-color: $color;
      box-shadow: none;
      border-radius: 0 0 0 5px;
      right: 0;
      span {
        &:nth-last-of-type(1) {
          transform: rotate(45deg);
          top: 14px;
        }
        &:nth-last-of-type(2) {
          opacity: 0;
        }
        &:nth-last-of-type(3) {
          transform: rotate(-45deg);
          top: 14px;
        }
      }
      &:hover {
        span {
          background-color: white;
        }
      }
    }
  }
  .toggler {
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: transparent;
    padding: 5px 7px;
    position: absolute;
    z-index: 9999999999999;
    top: 20px;
    border: none;
    border-radius: 0;
    right: 5px;
    display: none;
    @media (max-width: 991px) {
      display: inline-block;
    }
    &:hover {
      span {
        background-color: $color;
      }
    }
    div {
      width: 100%;
      position: relative;
      height: 100%;
    }
    span {
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 2px;
      border-radius: 50px;
      background-color: $black;
      left: 0;
      top: 5px;
      &:nth-last-of-type(2) {
        top: 13px;
      }
      &:nth-last-of-type(3) {
        top: 21px;
      }
    }
  }
  ul {
    display: inline-block;
    width: 300px;
    margin-top: 10px;
    @media (max-width: 1200px) {
      width: 300px;
    }
    @media (max-width: 991px) {
      width: 300px;
    }
    li {
      display: inline-block;
      width: 100%;
      text-align: left;
      &.active {
        border: none;
        .img-pnl {
          img {
            &:nth-of-type(1) {
              opacity: 0;
            }
            &:nth-of-type(2) {
              opacity: 1;
            }
          }
        }
      }
      a {
        display: flex;
        width: 100%;
        font-size: 15px;
        position: relative;
        font-weight: 700;
        padding: 15px 15px;
        color: $color;
        i {
          font-size: 22px;
        }
        .img-pnl {
          width: 26px;
          height: 26px;
          position: relative;
          margin-right: 10px;
          @media (max-width: 991px) {
            margin-right: 20px;
          }
          img {
            position: absolute;
            left: 0;
            top: 0;
            @include trans1();
            &:nth-of-type(2) {
              opacity: 0;
            }
          }
        }
        &:before {
          position: absolute;
          content: "";
          left: 0;
          top: 10px;
          bottom: 10px;
          width: 5px;
          border-radius: 0 15px 15px 0;
          opacity: 0;
        }
        img {
          margin-right: 12px;
          @media (max-width: 991px) {
            margin-right: 20px;
          }
        }
        &.active,
        &:hover {
          opacity: 1;
          color: $white;
          background: $color;
          .img-pnl {
            img {
              &:nth-of-type(1) {
                opacity: 0;
              }
              &:nth-of-type(2) {
                opacity: 1;
              }
            }
          }
        }
      }
      &.active a {
        opacity: 1;
        background: $color;
        color: $white;
        &:before {
          opacity: 1;
        }
      }
    }
  }
  .mobile-view {
    display: none;
    @media (max-width: 991px) {
      display: inline-block;
      width: 100%;
    }
  }
}
.Coin-managment {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px;
  color: #ad79f7;
  font-weight: bold;
  font-size: 16px;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
  width: 300px;
}

.Coin-managment:hover {
  color: white;
  background-color: #ad79f7;
}

.dropdown-basic-button {
  width: 100%; /* Ensure the DropdownButton spans the full width */
}

.dropdown-basic-button .dropdown-toggle {
  width: 100%; /* Ensure the toggle button takes full width */
}

.Logo-imageCM{
  height:25px;
  width: 25px;
}
.Arrow-DropDown{
margin-left: 10px;
width: 30px;
height: 30px;
}
/* Side Bar Ends Here
===================== */
